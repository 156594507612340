@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./fonts.css');

#root {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'Pretendard-Regular';
}

body {
  min-height: 100vh;
}

.mobileContainer {
  height: calc(100vh - 256px);
}
